<template>
  <div
    ref="root"
    style="height: 100%; width: 100%; position: relative; min-height: 150px"
    :class="{ 'chart--error': hasErrors }"
  >
    <loading :active="loading" overlay />
    <chart-error v-if="hasErrors" :error="actualError" />
    <div
      v-else
      ref="chart"
      style="height: 100%; width: 100%; position: relative"
    />
  </div>
</template>
<script>
import c3 from 'c3'
import 'c3/c3.min.css'
import Loading from '@/components/Loading'
import ChartError from '@/components/ChartError'

const colors = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#aaaa11',
  '#6633cc',
  '#8b0707',
  '#3b3eac'
]

export default {
  components: { Loading, ChartError },
  props: {
    sync: {
      type: Boolean,
      default: true
    },
    series: {
      type: Object,
      required: true
    },
    queryData: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      required: true,
      validator (value) {
        return ['pie', 'bar', 'column', 'line'].indexOf(value) !== -1
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      chart: null
    }
  },
  computed: {
    actualError () {
      if (this.noData) {
        return this.$t('chart.errorNoData')
      }

      return this.error
    },
    hasErrors () {
      return !this.loading && !!this.actualError
    },
    options () {
      if (this.type === 'line') {
        return this.optionsLine
      }

      if (this.type === 'pie') {
        return this.optionsPie
      }

      if (this.type === 'bar') {
        return this.optionsBar
      }

      if (this.type === 'column') {
        return this.optionsColumn
      }

      return this.optionsLine
    },
    optionsBar () {
      if (!this.series.columns[1] || this.series.columns[1].length === 1) {
        return {}
      }
      return {
        data: {
          type: 'bar',
          color (color, d) {
            if (typeof d.index === 'undefined') {
              return color
            }

            const idx = d.index % 10
            return colors[idx] || color
          },
          onclick: (d, ele) => {
            this.clickHandle(d, 'bar')
          },
          ...this.series
        },
        tooltip: {
          grouped: false
        },
        legend: {
          show: false
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              centered: true
            }
          },
          rotated: true
        }
      }
    },
    optionsColumn () {
      if (!this.series.columns[1] || this.series.columns[1].length === 1) {
        return {}
      }

      return {
        data: {
          type: 'bar',
          color (color, d) {
            if (typeof d.index === 'undefined') {
              return color
            }

            const idx = d.index % 10
            return colors[idx] || color
          },
          onclick: (d, ele) => {
            this.clickHandle(d, 'bar')
          },
          ...this.series
        },
        tooltip: {
          grouped: false
        },
        legend: {
          show: false
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              centered: true
            }
          }
        }
      }
    },
    optionsPie () {
      if (!this.series.columns || !this.series.columns.length) {
        return {}
      }

      return {
        data: {
          type: 'pie',
          onclick: (d, ele) => {
            this.clickHandle(d, 'pie')
          },
          ...this.series
        },
        color: {
          pattern: colors
        }
      }
    },
    optionsLine () {
      if (!this.series.columns || this.series.columns.length === 1) {
        return {}
      }

      return {
        data: {
          onclick: (d, ele) => {
            this.clickHandle(d, 'line')
          },
          ...this.series
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%d/%m/%Y %H:%M'
            }
          }
        },
        color: {
          pattern: colors
        },
        grid: {
          y: {
            show: true
          }
        }
      }
    },
    noData () {
      return (
        !this.options.data ||
        !this.options.data.columns ||
        !this.options.data.columns.length
      )
    }
  },
  watch: {
    options () {
      this.$nextTick(this.generate)
    }
  },
  beforeDestroy () {
    this.destroyChart()
  },
  mounted () {
    if (!this.sync) {
      return
    }

    this.generate()
  },
  methods: {
    clickHandle (item, chart) {
      var value = ''
      if (chart === 'pie' || chart === 'line') {
        value = item.id
      }

      if (chart === 'bar') {
        value = this.series.columns[0][item.index + 1]
      }

      if (!this.queryData || !this.queryData.query) {
        return
      }

      const queryMatches = this.queryData.query.match(
        /(.+?)\|.(\w+ by (.*?)(?: |\||$))/m
      )
      if (!queryMatches || queryMatches.length < 4) {
        return
      }

      const linkMatches = this.queryData.query.match(
        /(.+?)\|.*link (.+?)(?:\||$)/m
      )
      const subQuery =
        linkMatches && linkMatches.length >= 3 ? ` | ${linkMatches[2]}` : ''
      const routeData = this.$router.resolve({
        name: 'search',
        query: {
          range: this.queryData.range,
          q: `${queryMatches[1].replace(/\s+$/, '')} ${
            queryMatches[3]
          }:${value}${subQuery}`
        }
      })

      const win = window.open(routeData.href, '_blank')
      win.focus()
    },
    destroyChart () {
      if (!this.chart) {
        return
      }

      this.chart.destroy()
      this.chart = null
    },
    resize () {
      const el = this.$refs.chart
      if (!el) {
        return
      }

      if (!this.chart || !this.chart.internal || !this.chart.internal.api) {
        return
      }

      const root = this.$refs.root
      const width = root ? root.clientWidth : el.clientWidth
      const height = root ? root.clientHeight : el.clientHeight

      this.chart.internal.api.resize({
        width,
        height
      })
    },
    generate () {
      this.destroyChart()
      if (this.noData) {
        return
      }

      const el = this.$refs.chart
      if (!el) {
        return
      }

      const root = this.$refs.root
      const width = root ? root.clientWidth : el.clientWidth
      const height = root ? root.clientHeight : el.clientHeight
      const self = this

      this.chart = c3.generate({
        bindto: el,
        size: {
          width,
          height
        },
        onresize () {
          self.resize()
        },
        ...this.options
      })
    }
  }
}
</script>
<style lang="scss">
$x: #1f2128;
$x-light: lighten($x, 5%);
$y: #dddddf;
$y-dark: darken($y, 5%);

.chart--error {
  position: relative;
}

.c3 {
  text {
    fill: currentColor;
  }

  line,
  path {
    stroke: currentColor;

    &.c3-arc {
      stroke: #fff;
    }
  }

  .dashboard.is-dark & {
    color: $y;
  }
}

.c3-grid {
  line {
    opacity: 0.5;
    .dashboard.is-dark & {
      stroke: lighten($x, 30%);
    }
  }
}

.c3-circle {
  r: 3.5;
  stroke-width: 2px;
  stroke: #fff;
  .dashboard.is-dark & {
    stroke: $x-light;
  }
}

.c3-line {
  stroke-width: 2px;
}

.c3-tooltip {
  .dashboard.is-dark & {
    background-color: $x-light;
    box-shadow: none;
    tr {
      border-color: lighten($x, 15%);
    }

    td {
      background-color: $x;
    }

    th {
      background-color: lighten($x, 15%);
    }
  }
}
</style>
