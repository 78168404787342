<template>
  <div class="visualization">
    <template v-if="table">
      <data-table
        v-if="!loading && hasData" class="visualization__content table-viz" :items="items"
        sort-by="key"
        :pagination-side="1" :disable-filtering="true" :pagination="['top']"
        :page.sync="page" :theme="$_ui_theme_tables"
        :per-page="perPage" @loaded="tableLoaded"
      >
        <data-column
          v-for="col in data.columns" :key="col" :field="col"
          :sortable="false"
        >
          <template slot-scope="props">
            <template v-if="col === '#timestamp'">
              {{ format(new Date(props.item[col]), 'dd/MM/yyyy HH:mm:ss') }}
            </template>

            <template v-else-if="props.item[col] instanceof Array">
              <div class="tags">
                <span v-for="(v, index) in tableCellArray(props.item[col])" :key="index" class="tag is-link is-light">
                  {{ v }}
                </span>
              </div>
            </template>

            <template v-else>
              <div style="width: auto">
                <popover
                  placement="right" width="300px" style="padding: 0"
                  @shown="shown(props.item[col], col)"
                >
                  <a
                    slot="trigger" class="is-text break-text" href="#"
                    @click.prevent
                  >

                    <span>{{ props.item[col] }}</span>

                  </a>

                  <template v-slot:default="slotProps">
                    <data-table
                      v-if="slotProps.active" :items="searchItems" disable-filtering
                      class="hidden-y"
                      :theme="$_ui_theme_tables" :pagination="[]"
                    >
                      <data-column field="value" label="Value" :sortable="false" />

                      <data-column field="count" label="Count" :sortable="false" />
                    </data-table>

                    <div class="has-text-left is-size-7" style="margin-top: -0.6em">
                      <div class="menu sidebar-menu">
                        <div class="menu-list-wrapper">
                          <ul class="menu-list" style="padding: 0; font-size: 0.9em">
                            <li class="menu-item">
                              <a href="#" @click.prevent="searchByValue">

                                <octicon :icon="search" />
                                Search with Value
                              </a>
                            </li>

                            <li class="menu-item">
                              <a href="#" @click.prevent="searchByTime">

                                <octicon :icon="search" />
                                Search value By Time
                              </a>
                            </li>

                            <li class="menu-item">
                              <a href="#" @click.prevent="customClick">

                                <octicon :icon="search" />
                                Open Custom View
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </template>
                </popover>
              </div>
            </template>
          </template>
        </data-column>
      </data-table>

      <template v-else-if="!loading && !hasData">
        <div
          style="
            height: 100%;
            width: 100%;
            position: relative;
            min-height: 150px;
          "
        >
          <chart-error :error="$t('chart.errorNoData')" />
        </div>
      </template>
    </template>

    <template v-else-if="sum">
      <div class="visualization__content">
        <p class="has-text-centered is-size-1 has-text-weight-bold">
          <a href="#" style="color: inherit" @click.prevent="lableClick">
            {{ total | abbr }}
            <span class="is-size-2 has-text">{{ unit }}</span>

          </a>
        </p>
      </div>
    </template>

    <template v-else-if="map">
      <world-map class="visualization__content" :data="data" :loading="loading" />
    </template>

    <template v-else-if="bpmap">
      <bp-map class="visualization__content" :data="data" :loading="loading" />
    </template>

    <chart
      v-else-if="type" ref="chart" class="visualization__content"
      :loading="loading" :query-data="fullQuery"
      :series="series" :type="type" :style="{ height: chartHeight }"
    />

    <div class="visualization__footer">
      <slot name="below-chart" :sum="sum" />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { search } from 'octicons-vue'
import { getName } from 'country-list'
import { DataTable, DataColumn } from '@cyradar/ui'
import { makeLineData, makePieData, makeBarData } from '@/utils'
import Chart from '@/components/Chart'
import WorldMap from '@/components/WorldMap'
import ChartError from '@/components/ChartError'
import BPMap from '@/components/BPMap'
export default {
  components: {
    Chart,
    WorldMap,
    'bp-map': BPMap,
    DataTable,
    DataColumn,
    ChartError
  },
  filters: {
    abbr (value) {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(3) + ' M'
      }

      return value.toLocaleString()
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    chartHeight: {
      type: [String, Number],
      default: '100%'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loaded: false,
      searchValue: '',
      searchField: '',
      page: 1,
      perPage: 10
    }
  },
  computed: {
    search () {
      return search
    },
    type () {
      return this.data && this.data.type
    },
    fullQuery () {
      if (!this.data.query) {
        return {
          query: '',
          range: this.data.range
        }
      }
      return {
        query: this.data.query,
        range: this.data.range
      }
    },
    filterQuery () {
      if (!this.data || !this.data.query) {
        return ''
      }

      const queryMatches = this.data.query.match(/(.+?)\|.+/m)
      if (!queryMatches || queryMatches.length < 2) {
        return ''
      }
      return queryMatches[1].trim()
    },
    indexQuery () {
      if (!this.data || !this.data.query) {
        return ''
      }

      const matches = this.data.query.match(/^(index:(.*?))(\|(.*))?$/m)
      if (!matches) {
        return ''
      }

      return matches[1]
    },
    unit () {
      if (!this.data && !this.data.unit) {
        return ''
      } else {
        return this.data.unit
      }
    },
    sum () {
      return this.type === 'sum'
    },
    table () {
      return this.type === 'table'
    },
    pie () {
      return this.type === 'pie'
    },
    bar () {
      return this.type === 'bar'
    },
    column () {
      return this.type === 'column'
    },
    line () {
      return this.type === 'line'
    },
    map () {
      return this.type === 'map'
    },
    bpmap () {
      return this.type === 'bpmap'
    },
    series () {
      if (!this.data) {
        return {
          columns: []
        }
      }

      if (this.pie) {
        return makePieData(this.data.events)
      }

      if (this.bar) {
        return makeBarData(this.data.events)
      }

      if (this.column) {
        return makeBarData(this.data.events)
      }

      if (this.line) {
        const x = this.data.events.map(event => {
          return {
            name: event.key,
            data: (event.data || []).map(hit => [hit.key, hit.doc_count])
          }
        })

        return makeLineData(
          x,
          this.data.interval,
          this.data.start,
          this.data.end
        )
      }

      return {
        columns: []
      }
    },
    total () {
      return (this.data && this.data.total) || 0
    },
    hasData () {
      return this.data && this.data.events && this.data.events.length
    }
  },

  watch: {
    data (val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        return
      }

      this.loaded = false
    }
  },
  mounted () { },
  methods: {
    shown (v, f) {
      this.searchValue = v
      this.searchField = f
    },
    format,
    tableCellArray (v) {
      if (!(v instanceof Array)) {
        return v
      }

      return v
        .map(x => {
          if (!x.country || !x.country.iso_code) {
            return x
          }

          return getName(x.country.iso_code)
        })
        .filter((el, pos, arr) => arr.indexOf(el) === pos)
    },
    resize () {
      if (!this.$refs.chart) {
        return
      }

      this.$nextTick(() => {
        this.$refs.chart.resize()
      })
    },
    searchByValue () {
      if (this.filterQuery === '') {
        return
      }

      const routeData = this.$router.resolve({
        name: 'search',
        query: {
          range: this.data.range,
          q: `${this.filterQuery} ${this.searchField}:${this.searchValue}`
        }
      })

      const win = window.open(routeData.href, '_blank')
      win.focus()
    },
    searchByTime () {
      if (this.filterQuery === '') {
        return
      }

      const routeData = this.$router.resolve({
        name: 'search',
        query: {
          range: this.data.range,
          q: `${this.filterQuery} ${this.searchField}:${this.searchValue} | timechart by _index`
        }
      })

      const win = window.open(routeData.href, '_blank')
      win.focus()
    },
    customClick () {
      if (!this.data || !this.data.query || this.data.query === '') {
        return
      }

      const linkMatches = this.data.query.match(/(.+?)\|.*link (.+?)(?:\||$)/m)
      const subQuery =
        linkMatches && linkMatches.length >= 3 ? ` | ${linkMatches[2]}` : ''

      const routeData = this.$router.resolve({
        name: 'search',
        query: {
          range: this.data.range,
          q: `${this.filterQuery} ${this.searchField}:${this.searchValue}${subQuery}`
        }
      })

      const win = window.open(routeData.href, '_blank')
      win.focus()
    },
    lableClick () {
      if (!this.data || !this.data.query || this.data.query === '') {
        return
      }

      if (this.filterQuery === '') {
        return
      }

      const linkMatches = this.data.query.match(/(.+?)\|.*link (.+?)(?:\||$)/m)
      const subQuery =
        linkMatches && linkMatches.length >= 3 ? ` | ${linkMatches[2]}` : ''

      const routeData = this.$router.resolve({
        name: 'search',
        query: {
          range: this.data.range,
          q: `${this.filterQuery} ${subQuery}`
        }
      })

      const win = window.open(routeData.href, '_blank')
      win.focus()
    },
    searchItems () {
      if (this.filterQuery === '') {
        return {
          total: 0,
          items: [
            {
              value: this.searchValue,
              count: 0
            }
          ]
        }
      }

      return this.$_search({
        query: `${this.filterQuery} ${this.searchField}:${this.searchValue}| count by _index`,
        start: this.data.start,
        end: this.data.end,
        pure: true
      }).then(data => {
        if (!data) {
          return {
            total: 0,
            items: [
              {
                value: this.searchValue,
                count: 0
              }
            ]
          }
        }
        return {
          total: 0,
          items: [
            {
              value: this.searchValue,
              count: parseInt(data.total)
            }
          ]
        }
      })
    },
    count () {
      if (!this.data) {
        return Promise.resolve(0)
      }

      if (!this.loaded && this.data.total) {
        return Promise.resolve(this.data.total)
      }

      if (!this.indexQuery) {
        return Promise.resolve(0)
      }

      return this.$_search({
        query: `${this.indexQuery} | count by _index`,
        start: this.data.start,
        end: this.data.end,
        pure: true
      }).then(data => {
        if (!data) {
          return 0
        }

        return parseInt(data.total)
      })
    },
    items (filtering, sorting, { page }) {
      if (!this.data && !this.data.chartType) {
        return {
          total: 0,
          items: []
        }
      }

      if (this.data.chartType === 'count_statistic') {
        this.perPage = this.data.total

        const toBeFlatten = []
        this.data.events.forEach((x) => {
          toBeFlatten.push(this.flattenObj(x))
        })

        return {
          total: this.data.total,
          items: toBeFlatten
        }
      }

      return this.count()
        .then(total => (total > 10000 ? 10000 : total))
        .then(total => {
          if (total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          if (!this.loaded && this.data.events instanceof Array) {
            this.perPage = this.data.events.length

            const toBeFlatten = []
            this.data.events.forEach((x) => {
              toBeFlatten.push(this.flattenObj(x))
            })

            return {
              total,
              items: toBeFlatten
            }
          }

          const payload = {
            query: this.data.query,
            start: this.data.start,
            end: this.data.end,
            from: this.perPage * (page - 1)
          }

          return this.$_search({ ...payload, pure: true }).then(data => {
            if (!data || !data.data) {
              return {
                total: 0,
                items: []
              }
            }

            const toBeFlatten = []
            data.data.forEach((x) => {
              toBeFlatten.push(this.flattenObj(x))
            })

            this.perPage = 10
            return {
              total,
              items: toBeFlatten
            }
          })

          // return result
        })
    },
    tableLoaded (result) {
      this.loaded = true
      this.$emit('page', this.page)
    },
    flattenObj (ob) {
      // The object which contains the
      // final result
      const result = {}

      // loop through the object "ob"
      for (const i in ob) {
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
          const temp = this.flattenObj(ob[i])
          for (const j in temp) {
            // Store temp in result
            result[i + '.' + j] = temp[j]
          }

          continue
        }

        // Else store ob[i] in result directly
        result[i] = ob[i]
      }
      return result
    },

    flatten (obj, keys) {
      const clone = JSON.parse(JSON.stringify(obj))
      keys.forEach(key => {
        if (key.startsWith('#')) {
          return
        }
        key.split('.').reduce((acc, val) => {
          if (typeof acc !== 'object') {
            return acc
          }
          const v = acc[val]
          if (v instanceof Array) {
            acc[val] = v[0]
            return v[0]
          }
          return v
        }, clone)
      })
      return clone
    }
  }
}
</script>

<style lang="scss">
.chart--error {
  position: relative;
}

.visualization {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.break-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.visualization__content {
  flex: 1 1 auto;
  overflow-y: hidden;
}

.visualization__footer {
  flex-shrink: 0;
}

.popover__content {
  padding: 0;
}

.popover {
  max-width: unset;
}

.menu-item {
  border-top: 1px solid #888;
  background: #353945;
}
</style>
