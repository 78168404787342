<template>
  <div class="chart__error">
    <slot>
      <img src="@/assets/error.png">
      <p>{{ error }}</p>
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    error: {
      type: String,
      default () {
        return this.$t('chart.error')
      }
    }
  }
}
</script>
<style lang="scss">
.chart__error {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

  background: #fff;
  z-index: 9;
  transition: opacity .3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: 50%;
    max-height: 6rem;
    min-height: 3rem;
  }

  p {
    font-size: .9rem;
    margin-top: 1em;
    text-transform: uppercase;
  }

  min-height: 150px;

  .dashboard.is-dark & {
    background-color: #2a2d36;
  }
}
</style>
