<template>
  <div style="position: relative;">
    <div
      v-if="tooltipPosition && (tooltipCountry || tooltipLocation)" class="tooltip" :style="{
        top: tooltipPosition.y - 12 + 'px',
        left: tooltipPosition.x + 12 + 'px'
      }"
    >
      <template v-if="tooltipCountry">
        <h4 class="tooltip__title">
          {{ tooltipCountry.name }} <span v-if="tooltipCountry.marked" class="has-text-weight-normal">({{ tooltipCountry.count.toLocaleString() }})</span>
        </h4>
      </template>

      <template v-if="tooltipLocation">
        <h4 v-if="tooltipLocation.country" class="tooltip__title">
          {{ tooltipLocation.country.name }}
        </h4>
        <h5 class="tooltip__subtitle">
          {{ tooltipLocation.longitude }}, {{ tooltipLocation.latitude }}
        </h5>
        <ul v-if="tooltipLocation.items && tooltipLocation.items.length">
          <li v-for="item in tooltipLocation.items" :key="item">
            {{ item }}
          </li>
        </ul>
      </template>
    </div>

    <svg :viewBox="`0 0 ${width} ${height}`" class="world-map">
      <defs>
        <pattern
          id="smallGrid" class="pattern"
          width="5" height="5" patternUnits="userSpaceOnUse"
        >
          <path
            d="M 5 0 L 0 0 0 5" fill="none" stroke-width="0.5"
          />
        </pattern>
        <pattern
          id="grid" class="pattern"
          width="20" height="20" patternUnits="userSpaceOnUse"
        >
          <rect width="20" height="20" fill="url(#smallGrid)" />
          <path
            d="M 20 0 L 0 0 0 20" fill="none" stroke-width="1"
          />
        </pattern>
        <filter id="blur">
          <feGaussianBlur in="SourceGraphic" stdDeviation="0,0" />
        </filter>
      </defs>

      <rect width="100%" height="100%" fill="url(#grid)" />

      <!-- Countries -->
      <g>
        <path
          v-for="p in countries" :key="p.id" :d="p.d"
          :fill="p.fill" class="country" data-type="country"
          @mouseover.stop="mouseover($event, p)" @mouseleave="mouseleave"
        />
      </g>

      <!-- Connections -->
      <g>
        <g
          v-for="(curve, index) in curves" :key="index"
          class="connection__head"
          :style="{
            offsetPath: `path('${curve.d}')`,
            animationDelay: index + 's',
            animationDuration: curve.duration
          }"
        >
          <circle
            r="3"
            fill="#697ebb"
          />
          <circle
            r="1.7"
            fill="#6ee4cf"
          />
        </g>

        <g>
          <path
            v-for="(curve, index) in curves" :key="index"
            class="connection" :d="curve.d" :style="{
              strokeDasharray: curve.length,
              strokeDashoffset: curve.length,
              animationDelay: index + 's',
              animationDuration: curve.duration
            }"
          />
        </g>
      </g>

      <g
        v-for="(point, key) in locations" :key="key" :count="point.count"
        class="location"
      >
        <circle
          :cy="point.d[1]" :cx="point.d[0]" :r="radius(point.count)"
          class="pulse" :style="{
            transformOrigin: `${point.d[0]}px ${point.d[1]}px`
          }"
        />
        <circle
          :cy="point.d[1]" :cx="point.d[0]" :r="radius(point.count)"
          class="pulse"
          :style="{
            transformOrigin: `${point.d[0]}px ${point.d[1]}px`,
            animationDelay: '1s'
          }"
        />
        <circle
          :cy="point.d[1]" :cx="point.d[0]" :r="radius(point.count)"
          class="pulse-border" :style="{
            transformOrigin: `${point.d[0]}px ${point.d[1]}px`
          }"
          data-type="location" @mouseover.stop="mouseover($event, point)" @mouseleave="mouseleave"
        />
        <circle
          :cy="point.d[1]" :cx="point.d[0]" :r="radius(point.count)"
          data-type="location" @mouseover.stop="mouseover($event, point)" @mouseleave="mouseleave"
        />
      </g>

      <g v-if="currentLocation" class="location location--current">
        <circle
          :cy="currentLocation.d[1]" :cx="currentLocation.d[0]" :r="currentLocation.r"
          class="pulse"
          :style="{
            transformOrigin: `${currentLocation.d[0]}px ${currentLocation.d[1]}px`
          }"
        />
        <circle
          :cy="currentLocation.d[1]" :cx="currentLocation.d[0]" :r="currentLocation.r"
          class="pulse"
          :style="{
            transformOrigin: `${currentLocation.d[0]}px ${currentLocation.d[1]}px`,
            animationDelay: '1s'
          }"
        />
        <circle
          :cy="currentLocation.d[1]" :cx="currentLocation.d[0]" :r="currentLocation.r"
          class="pulse-border"
          :style="{
            transformOrigin: `${currentLocation.d[0]}px ${currentLocation.d[1]}px`
          }"
        />
        <circle
          :cy="currentLocation.d[1]" :cx="currentLocation.d[0]" :r="currentLocation.r"
        />
      </g>
    </svg>

    <loading :active="loading" overlay />
  </div>
</template>
<script>
import { geoPath, geoMercator, scaleLinear, scaleThreshold } from 'd3'
import debounce from 'lodash.debounce'
import Loading from '@/components/Loading'
import { dotGet } from '@/utils'

export default {
  components: { Loading },
  props: {
    data: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 961
    },
    height: {
      type: Number,
      default: 501
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      world: {},
      tooltipCountry: null,
      tooltipLocation: null,
      tooltipPosition: null
    }
  },
  computed: {
    normalized () {
      const output = {}

      if (!this.data.field) {
        return {}
      }

      this.data.originalData.forEach(row => {
        if (!row || !row[this.data.field]) {
          return
        }

        const { count, locations } = row[this.data.field]
        const item = dotGet(row, this.data.rootField)
        let loaded = false

        locations.forEach(({ location, country }) => {
          const code = country.iso_code
          const coordinate = `${location.longitude},${location.latitude}`
          const defaultLocations = {
            [coordinate]: {
              ...location,
              count,
              items: [item]
            }
          }

          if (!output[code]) {
            output[code] = {
              locations: defaultLocations,
              count,
              items: [item]
            }
            loaded = true
            return
          }

          if (!output[code].locations) {
            output[code].locations = defaultLocations
            output[code].items = [item]
            loaded = true
            return
          }

          if (loaded) {
            return
          }

          loaded = true
          output[code].count += count

          output[code].items = output[code].items || []
          if (output[code].items.indexOf(item) === -1) {
            output[code].items.push(item)
          }

          if (output[code].locations[coordinate]) {
            output[code].locations[coordinate].count += count
            if (output[code].locations[coordinate].items === -1) {
              output[code].locations[coordinate].items.push(item)
            }

            return
          }

          output[code].locations[coordinate] = defaultLocations[coordinate]
        })
      })

      return output
    },
    fill () {
      return scaleThreshold()
        .domain(this.countryRange)
        .range(['#1b243c', '#232e4e', '#2b395f', '#334371', '#3b4d83', '#435894', '#4b62a6'])
    },
    countryCounts () {
      return Object.values(this.normalized).map(c => c.count).sort((a, b) => a - b)
    },
    countryRange () {
      const values = this.countryCounts
      if (!values.length) {
        return []
      }

      const upper = values[values.length - 1]
      const lower = values[0]
      const diff = upper - lower
      const range = []

      for (let i = 0; i < 6; i++) {
        range.push(lower + i * Math.floor(diff / 5))
      }

      return range
    },
    countries () {
      if (!this.world) {
        return {}
      }

      return (this.world.features || []).reduce((acc, feature) => {
        const item = this.normalized[feature.id]
        const count = item ? item.count : 0

        acc[feature.id] = {
          id: feature.id,
          d: this.pathFactory(feature),
          fill: this.currentLocation && feature.id === this.currentLocation.country.iso_code ? '#89afeb' : this.fill(count),
          name: feature.properties.name,
          count,
          marked: !!item
        }

        return acc
      }, {})
    },
    locations () {
      return Object.keys(this.normalized).reduce((acc, k) => {
        const val = this.normalized[k]

        if (!val || !val.locations) {
          return acc
        }

        Object.keys(val.locations).forEach(key => {
          const location = val.locations[key]
          if (!location) {
            return acc
          }

          if (acc[key]) {
            acc[key] += (parseInt(location.count) || 0)
            return acc
          }

          acc[key] = {
            ...location,
            d: this.projection([location.longitude, location.latitude]),
            normalized: val,
            country: this.countries[k]
          }
        })

        return acc
      }, {})
    },
    connections () {
      if (!this.currentLocation) {
        return []
      }

      const { location } = this.currentLocation
      if (!location) {
        return []
      }

      const connections = []

      for (var key in this.locations) {
        const point = this.locations[key]

        if (this.data.direction_reverse) {
          connections.push([
            this.projection([point.longitude, point.latitude]),
            this.projection([location.longitude, location.latitude])
          ])
          continue
        }

        connections.push([this.projection([location.longitude, location.latitude]), this.projection([point.longitude, point.latitude])])
      }

      return connections
    },
    curves () {
      return this.connections.map(connection => {
        const { distance, d } = this.curveFactory(connection)
        const speed = 1 / 12
        const x = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        x.setAttributeNS(null, 'd', d)

        return {
          points: connection,
          d,
          distance,
          length: x.getTotalLength(),
          duration: (Math.round(distance) / speed) + 'ms'
        }
      })
    },
    $radius () {
      let top = 0
      if (this.countryCounts.length) {
        top = this.countryCounts[this.countryCounts.length - 1]
      }

      return scaleLinear().domain([0, top]).range([0, 6])
    },
    projection () {
      return geoMercator()
        .scale(this.width / 2.3 / Math.PI)
        .rotate([-11, 0])
        .translate([(this.width) / 2, this.height * 1.35 / 2])
    },
    pathFactory () {
      return geoPath().projection(this.projection)
    },
    curveFactory () {
      return (d) => {
        const from = d[0]
        const to = d[1]

        const dx = to[0] - from[0]
        const dy = to[1] - from[1]
        const dr = Math.sqrt(dx * dx + dy * dy)
        const diff = Math.random() * (1.5 - 2.1) + 2.1

        return {
          d: `M${from[0]},${from[1]} A${dr} ${dr * diff} 0 0 0 ${to[0]},${to[1]}`,
          distance: dr
        }
      }
    },
    currentLocation () {
      if (!this.data.currentLocation) {
        return
      }

      const { country, location } = this.data.currentLocation
      if (!location) {
        return
      }

      return {
        country,
        location,
        d: this.projection([location.longitude, location.latitude]),
        r: 3
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    mouseover: debounce(function ($event, item) {
      this.updatePosition($event)

      if ($event.target.dataset.type === 'country') {
        this.tooltipLocation = null
        this.tooltipCountry = item
        return
      }

      if ($event.target.dataset.type === 'location') {
        this.tooltipCountry = null
        this.tooltipLocation = item
      }
    }, 75, { maxWait: 500 }),
    mouseleave: debounce(function () {
      this.updatePosition()
    }, 75, { maxWait: 500 }),
    updatePosition ($event) {
      if (!$event) {
        this.tooltipPosition = null
        return
      }

      const rect = this.$el.getBoundingClientRect()
      this.tooltipPosition = {
        x: $event.pageX - (rect.left + (window.scrollX || window.pageXOffset)),
        y: $event.pageY - (rect.top + (window.scrollY || window.pageYOffset))
      }
    },
    radius (x) {
      const r = this.$radius(x)
      if (r < 2) {
        return 2
      }

      return r
    },
    init () {
      const world = () => import('@/assets/world.json')
      world().then(data => {
        const world = data.default
        this.world = world
      })
    }
  }
}
</script>
<style lang="scss">
.world-map {
  background-color: #1f2128;
}

.country {
  opacity: 0.8;
  stroke: #697ebb;
  stroke-width: 0.6;
  transition: fill 150ms linear;
  &:hover {
    fill: #576fb3;
    opacity: 1;
  }
}

.pattern {
  path {
    stroke: #2a2d36;
  }
}

.connection {
  fill: none;
  stroke: #6ee4cf;
  animation: draw infinite ease-in;
}

.connection__head {
  animation: pathy infinite ease-in;
  offset-distance: 0;
}

.location {
  circle {
    fill: #ee63a0;
    &:hover {
      cursor: pointer;
    }
  }

  circle.pulse-border {
    fill: none;
    stroke: #ee63a0;
    transform-origin: center;
    animation: pulse 3s cubic-bezier(0.39, 0.54, 0.41, 1.5) infinite;
    opacity: 1;
  }

  circle.pulse {
    animation: pulse-me 2s linear infinite;
    fill: #ee63a0;
  }

  &.location--current {
    circle {
      fill: #fac56d;
    }

    circle:not(:last-child) {
      fill: none;
      stroke: #fac56d;
    }
  }
}

.tooltip {
  color: #222;
  background: #fff;
  border-radius: 3px;
  padding: .5em;
  opacity: 0.9;
  position: absolute;
  font-size: 0.8rem;
}

.tooltip__title {
  font-weight: bold;
  font-size: 1rem;
}

.tooltip__subtitle {
  padding-bottom: .4em;
  margin-bottom: .4em;
  border-bottom: 1px solid #ddd;
}

@keyframes pathy {
  100% {
    offset-distance: 100%;
  }
}

@keyframes draw {
  0% {
    stroke-opacity: 0.5;
    stroke-width: 0.1;
  }

  50% {
    stroke-opacity: 1;
    stroke-width: 1;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-opacity: 0.5;
    stroke-width: 0.5;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.3);
    stroke-width: 3px;
    stroke-opacity: 1;
  }
  100% {
    transform: scale(2.5);
    stroke-width: 0;
    stroke-opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
</style>
